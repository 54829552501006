import * as React from 'react'
import { useEffect } from 'react';
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import A2TypePdf from '../../assets/images/type/a2_type_pdf.pdf'

const A2TypePage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainPlan'>
      {/* CONTENTS */}

      <ComMain
        ttl="PLAN"
        subttl="間取り"
        caption="image photo"
      >
        <StaticImage
          src="../../assets/images/plan/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../../assets/images/plan/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>


      <section className='sec-plan-detail'>
        <div className="c-content">

          <div className="detail-wrap">
            <div className="detail-box">
              <div className="img a2-type">
                <StaticImage
                  src="../../assets/images/type/a2_type.png"
                  alt=""
                  className="_pc"
                />
                <StaticImage
                  src="../../assets/images/type/a2_type_sp.png"
                  alt=""
                  className="_sp"
                />
              </div>
            </div>

            <div className="btn-box">
              <Link to="/plan/">
                <span>戻る</span>
              </Link>
              <a href={A2TypePdf} target="_blank" rel="noopener noreferrer">
                <span>印刷する</span>
              </a>
            </div>
          </div>

        </div>
      </section>


      <div className="c-content">
        <div className="com-note-wrap">
          <p className="c-note">※掲載の間取り図は計画段階の図面を基に描き起こしたものであり、実際とは異なります。また今後、施工上の理由等により計画に変更が生じる場合があります。家具、植栽、造作家具、調度品等は販売価格には含まれません。</p>
        </div>
      </div>


      {/* CONTENTS */}
    </main>
  )
}

export default A2TypePage
